import { apiClient } from 'helpers/ApiClient';
import { BackofficeNotification } from 'types/Notification';
import { logErrorResponse } from 'utils/loggerUtil';

export type FetchNotificationEventParams = {
  locale: string;
  operatingSystem: string;
  path: string;
};

const fetchNotificationEvent = async ({ locale, operatingSystem, path }: FetchNotificationEventParams) => {
  try {
    const { data } = await apiClient<BackofficeNotification>({
      params: {
        lang: locale,
        operatingSystem,
        path,
      },
      url: `/notifications/search`,
    });

    return data;
  } catch (error) {
    logErrorResponse('Notification Events Connector', error, 'Error fetching notification event');
    throw error;
  }
};

export { fetchNotificationEvent };
