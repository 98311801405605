import { useQuery } from '@tanstack/react-query';
import useRouter from 'hooks/useRouter';
import { notificationKeys } from '.';
import useUserAgent from '../../hooks/useUserAgent';
import { fetchNotificationEvent } from './connector';

const useNotificationEvent = () => {
  const { asPath: path, locale } = useRouter();
  const { operatingSystem } = useUserAgent();

  return useQuery({
    enabled: !!operatingSystem,
    queryFn: () => fetchNotificationEvent({ locale, operatingSystem, path }),
    queryKey: notificationKeys.notificationEvent({ locale, operatingSystem, path }),
  });
};

export { useNotificationEvent };
