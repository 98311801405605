import { useEffect, useState } from 'react';

const AMOUNT_MS_IN_DAY = 24 * 60 * 60 * 1000;
const AMOUNT_MS_IN_HOUR = AMOUNT_MS_IN_DAY / 24;
const AMOUNT_MS_IN_MINUTE = AMOUNT_MS_IN_HOUR / 60;
const AMOUNT_MS_IN_SECOND = AMOUNT_MS_IN_MINUTE / 60;

export const useCountdown = (targetDate: string) => {
  const timeUntilTarget = new Date(targetDate).getTime();
  const [currentTime, setCurrentTime] = useState(Date.now());
  const count = timeUntilTarget - currentTime;
  const countdownEnded = count <= 0;

  useEffect(() => {
    if (targetDate && !countdownEnded) {
      const interval = setInterval(() => {
        setCurrentTime(Date.now());
      }, 1000);
      return () => clearInterval(interval);
    }
  }, []);

  if (countdownEnded) {
    return { countdownEnded };
  }

  const days = Math.floor(count / AMOUNT_MS_IN_DAY);
  const hours = Math.floor((count % AMOUNT_MS_IN_DAY) / AMOUNT_MS_IN_HOUR);
  const minutes = Math.floor((count % AMOUNT_MS_IN_HOUR) / AMOUNT_MS_IN_MINUTE);
  const seconds = Math.floor((count % AMOUNT_MS_IN_MINUTE) / AMOUNT_MS_IN_SECOND);

  return { countdownEnded, days, hours, minutes, seconds };
};
