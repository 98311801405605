import Box from 'components/Box/Box';
import Typography from 'components/Typography/Typography';
import { StyledTimerLabel } from './TimerValue.styled';
import { ReactNode } from 'react';
import { Color } from 'types/Color';
import { FontSize } from 'constants/styling';

interface TimerValueProps {
  children: ReactNode;
  color?: Color;
  fontSize?: FontSize;
  label?: string;
}

const TimerValue = ({ children, color, fontSize, label }: TimerValueProps) => (
  <Box position="relative">
    <Typography color={color} fontSize={fontSize} marginBottom={0} type="h2">
      {children}
    </Typography>
    {label && <StyledTimerLabel color={color}>{label}</StyledTimerLabel>}
  </Box>
);

export default TimerValue;
