import Flex, { FlexProps } from 'components/Flex/Flex';
import { DELIMITERS, Delimiter } from 'constants/countdown';
import { FontSize } from 'constants/styling';
import { useCountdown } from 'hooks/useCountdown';
import { useIntl } from 'react-intl';
import { Color } from 'types/Color';
import TimerValue from './TimerValue';

interface CountdownProps extends FlexProps {
  className?: string;
  date: string;
  delimiter?: Delimiter;
  fontColor?: Color;
  fontSize?: FontSize;
}

/**
 * @deprecated
 * Use the Countdown component found in the ui folder instead
 */
const Countdown = ({
  className,
  date,
  delimiter = DELIMITERS.COLONS,
  fontColor = 'white',
  fontSize = 300,
  gap = 50,
  justifyContent = 'center',
  ...flexProps
}: CountdownProps) => {
  const { formatMessage } = useIntl();

  const genericTimerValueProps = { color: fontColor, fontSize };

  const { countdownEnded, days, hours, minutes, seconds } = useCountdown(date);

  if (!date || countdownEnded) return null;

  return (
    <Flex className={className} gap={gap} justifyContent={justifyContent} {...flexProps}>
      {days ? (
        <>
          <TimerValue {...genericTimerValueProps} label={formatMessage({ id: 'countdown_label_day' })}>
            {days}
          </TimerValue>
          <TimerValue {...genericTimerValueProps}>{delimiter}</TimerValue>
        </>
      ) : null}

      {hours ? (
        <>
          <TimerValue {...genericTimerValueProps} label={formatMessage({ id: 'countdown_label_hour' })}>
            {hours}
          </TimerValue>
          <TimerValue {...genericTimerValueProps}>{delimiter}</TimerValue>
        </>
      ) : null}

      {minutes ? (
        <>
          <TimerValue {...genericTimerValueProps} label={formatMessage({ id: 'countdown_label_minute' })}>
            {minutes}
          </TimerValue>
        </>
      ) : null}

      {!days && seconds ? (
        <>
          <TimerValue {...genericTimerValueProps}>{delimiter}</TimerValue>
          <TimerValue {...genericTimerValueProps} label={formatMessage({ id: 'countdown_label_second' })}>
            {seconds}
          </TimerValue>
        </>
      ) : null}
    </Flex>
  );
};

export default Countdown;
