import Countdown from 'components/Countdown/Countdown';
import Flex from 'components/Flex/Flex';
import Icon from 'components/Icon/Icon';
import NewsletterForm from 'components/NewsletterForm/NewsletterForm';
import Typography from 'components/Typography/Typography';
import config from 'config';
import { DEFAULT_TRANSITION_TIMING } from 'constants/animations';
import { DELIMITERS } from 'constants/countdown';
import { ICON_NAMES } from 'constants/icon';
import {
  DEFAULT_NOTIFICATION_POSITION,
  DELAY_EVENT_TIMER,
  NOTIFICATION_TEMPLATES,
  SELECTED_ANIMATION,
} from 'constants/notifications';
import { useNotificationEvent } from 'features/notificationEvent/queries';
import { AnimatePresence, motion } from 'framer-motion';
import { memo, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import isEqual from 'react-fast-compare';
import { tv } from 'tailwind-variants';

const notificationVariants = tv({
  base: 'z-base fixed flex max-w-[500px] flex-col gap-6 rounded bg-white p-6 shadow',
  variants: {
    position: {
      BOTTOM_CENTER: 'bottom-4 right-4 left-4 md:right-auto md:left-1/2 md:-translate-x-1/2',
      BOTTOM_LEFT: 'bottom-4 right-4 left-4 md:right-auto',
      BOTTOM_RIGHT: 'bottom-4 right-4 left-4 md:left-auto',
      TOP_CENTER: 'top-4 right-4 left-4 md:right-auto md:left-1/2 md:-translate-x-1/2',
      TOP_LEFT: 'top-4 right-4 left-4 md:right-auto',
      TOP_RIGHT: 'top-4 right-4 left-4 md:left-auto',
    },
  },
});

const NotificationEvent = () => {
  const [cookies, setCookie] = useCookies();

  const { data: notification } = useNotificationEvent();

  const [showEventNotification, setShowEventNotification] = useState(false);

  const notificationEventCookie = cookies?.[config?.notificationEvent?.key] || [];
  const customerHasHiddenEvent =
    notificationEventCookie.find((event: { code: string }) => event?.code === notification?.code)?.hidden || false;

  const handleSetCookie = () => {
    setCookie(
      config?.notificationEvent?.key,
      [...notificationEventCookie, { code: notification?.code, hidden: true }],
      {
        maxAge: config?.notificationEvent?.maxAge,
        path: '/',
      },
    );
  };

  const handleCloseEvent = () => {
    setShowEventNotification(false);
    handleSetCookie();
  };

  useEffect(() => {
    setTimeout(() => {
      setShowEventNotification(!customerHasHiddenEvent);
    }, DELAY_EVENT_TIMER);
  }, []);

  if (!(notification && showEventNotification)) {
    return null;
  }

  const position = notification?.position ?? DEFAULT_NOTIFICATION_POSITION;

  return (
    <AnimatePresence>
      <motion.div
        className={notificationVariants({ position: notification?.position })}
        animate="enter"
        exit="exit"
        initial="exit"
        transition={DEFAULT_TRANSITION_TIMING}
        variants={SELECTED_ANIMATION?.[position]}
      >
        <Flex alignItems="flex-start" justifyContent="space-between">
          <Typography marginBottom={0} type="h3">
            {notification?.title}
          </Typography>

          <div onClick={() => handleCloseEvent()} role="button" tabIndex={0}>
            <Icon type="custom" color="whisper-300" name={ICON_NAMES.TIMES} size={75} />
          </div>
        </Flex>

        <Typography>{notification?.description}</Typography>

        {(notification?.template === NOTIFICATION_TEMPLATES.SUBSCRIBE_TIMER ||
          notification?.template === NOTIFICATION_TEMPLATES.TIMER) &&
          notification?.endDate && (
            <Countdown
              className="mb-6 self-start"
              date={notification?.endDate}
              delimiter={DELIMITERS.SPACES}
              fontColor="black"
              margin="auto"
            />
          )}

        {(notification.template === NOTIFICATION_TEMPLATES?.SUBSCRIBE_TIMER ||
          notification.template === NOTIFICATION_TEMPLATES?.SUBSCRIBE) && (
          <NewsletterForm formId={notification?.code} />
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default memo(NotificationEvent, isEqual);
